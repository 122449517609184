<div class="login-page">
  <div class="logo-text-container">
    <h3>{{ "__transformEvaluation" | translate }}</h3>
    <p>{{ "__disneyEvaluation" | translate }}</p>
    <img src="url(assets/evaluation_back.svg)" alt="" />
  </div>
  <div class="login-container">
    <form
      (ngSubmit)="handleAction()"
      class="login-form"
      *ngIf="state !== 'reset'"
    >
      <ng-container *ngIf="state !== 'recover'">
        <span>
          <input
            type="email"
            placeholder="{{ '__user' | translate }}"
            [(ngModel)]="email"
            name="email"
          />
        </span>
        <span>
          <input
            type="password"
            placeholder="{{ '__password' | translate }}"
            [(ngModel)]="password"
            name="password"
          />
        </span>
        <p class="recover_pass_text">
          <a routerLink="/recover">{{ "__forgotPass" | translate }}</a>
        </p>
        <button type="submit" class="btn btn-main login">
          {{ "__login" | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="state === 'recover'">
        <h2>{{ "__resetPass" | translate }}</h2>
        <span>
          <input
            type="email"
            placeholder="{{ '__user' | translate }}"
            [(ngModel)]="email"
            name="email"
          />
        </span>
        <p class="recover_pass_text">
          <a routerLink="/login">{{ "__backLogin" | translate }}</a>
        </p>

        <button type="submit" class="btn btn-main recover">
          {{ "__reset" | translate }}
        </button>
      </ng-container>
    </form>

    <form
      [formGroup]="resetForm"
      (ngSubmit)="handleAction()"
      novalidate
      *ngIf="state === 'reset'"
      class="login-form"
    >
      <span>
        <input
          type="password"
          [placeholder]="'__newPassword' | translate"
          formControlName="newPassword"
          name="password"
        />
        <input
          type="password"
          [placeholder]="'__repeatPassword' | translate"
          formControlName="confirmPassword"
          name="confirmPassword"
        />
      </span>

      <div
        *ngIf="!resetForm.valid && newPassword.value"
        class="error-hints-container pass-requirements"
      >
        <h6>{{ "__passwordsMust" | translate }}:</h6>
        <ul>
          <li>
            <i
              class="material-icons"
              [ngClass]="{
                passed: !resetForm.controls['newPassword'].hasError('minlength')
              }"
            >
              {{
                resetForm.controls["newPassword"].hasError("minlength")
                  ? "radio_button_unchecked"
                  : "check_circle_outline"
              }}
            </i>
            <span>
              {{ "__passwordLenght" | translate }}
            </span>
          </li>
          <li>
            <i
              class="material-icons"
              [ngClass]="{
                passed:
                  !resetForm.controls['newPassword'].hasError('hasUppercase')
              }"
            >
              {{
                resetForm.controls["newPassword"].hasError("hasUppercase")
                  ? "radio_button_unchecked"
                  : "check_circle_outline"
              }}
            </i>
            <span>
              {{ "__passwordUppercase" | translate }}
            </span>
          </li>

          <li>
            <i
              class="material-icons"
              [ngClass]="{
                passed:
                  !resetForm.controls['newPassword'].hasError('hasLowercase')
              }"
            >
              {{
                resetForm.controls["newPassword"].hasError("hasLowercase")
                  ? "radio_button_unchecked"
                  : "check_circle_outline"
              }}
            </i>
            <span>
              {{ "__passwordLowercase" | translate }}
            </span>
          </li>

          <li>
            <i
              class="material-icons"
              [ngClass]="{
                passed: !resetForm.controls['newPassword'].hasError('hasNumber')
              }"
            >
              {{
                resetForm.controls["newPassword"].hasError("hasNumber")
                  ? "radio_button_unchecked"
                  : "check_circle_outline"
              }}
            </i>
            <span>
              {{ "__passwordNumber" | translate }}
            </span>
          </li>

          <li>
            <i
              class="material-icons"
              [ngClass]="{
                passed: !resetForm.controls['newPassword'].hasError('hasSymbol')
              }"
            >
              {{
                resetForm.controls["newPassword"].hasError("hasSymbol")
                  ? "radio_button_unchecked"
                  : "check_circle_outline"
              }}
            </i>
            <span>
              {{ "__passwordSymbol" | translate }}
            </span>
          </li>

          <li>
            <i
              class="material-icons"
              [ngClass]="{
                passed:
                  !resetForm.controls['confirmPassword'].hasError(
                    'passwordMismatch'
                  )
              }"
            >
              {{
                resetForm.controls["confirmPassword"].hasError(
                  "passwordMismatch"
                )
                  ? "radio_button_unchecked"
                  : "check_circle_outline"
              }}
            </i>
            <span>
              {{ "__passwordsMatch" | translate }}
            </span>
          </li>
        </ul>
      </div>

      <p *ngIf="invalidPassReset" class="error-msg">
        * {{ "__invalidPasswordResetError" | translate }}
      </p>
      <p class="recover_pass_text">
        <a routerLink="/login">{{ "__backLogin" | translate }}</a>
      </p>
      <button
        type="submit"
        class="btn btn-main recover"
        [disabled]="!resetForm.valid"
      >
        {{ "__confirm" | translate }}
      </button>
    </form>
  </div>
</div>
