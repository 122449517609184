import { Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AlertsService } from '../../services/alerts.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { matchPassword, patternValidator } from '../../utils';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { User } from '../../classes/user';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, RouterModule ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  email = '';
  success = '';
  password = '';
  error: boolean = false;
  user: User;
  state: string = '';

  resetForm: FormGroup = new FormGroup({
    newPassword: new FormControl('',
      [
        Validators.minLength(10),
        patternValidator(/\d/, { hasNumber: true }),
        patternValidator(/[A-Z]/, { hasUppercase: true }),
        patternValidator(/[a-z]/, { hasLowercase: true }),
        patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSymbol: true }),
        Validators.required
      ]
    ),
    confirmPassword: new FormControl('', Validators.required)
  },
    // {
    //   validators: matchPassword('newPassword', 'confirmPassword'),
    // }
  );
  invalidPassReset = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertsService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.error = false;
    this.state = this.route.snapshot.data[ 'state' ];
  }

  handleAction(): void {
    this.state = this.route.snapshot.data[ 'state' ];
    if (this.state === 'login') {
      this.login();
    }
    if (this.state === 'recover') {
      this.recover();
    }
    if (this.state === 'reset') {
      this.confirm();
    }
  }

  login(): void {
    this.userService.login(this.email, this.password).pipe().subscribe(
      token => {
        this.userService.saveToken(token);
        this.user = this.userService.setUser(token.user.name, token.user.role, token.user.role_id, token.user.permissions);
        this.userService.saveUser();
        this.navigatePath();
      },
      error => {
        const handleError = Object.keys(error.error)[ 0 ] === 'non_field_errors' ? { type: 'error', message: this.translateService.instant("__incorrectEmail") } : this.alertService.formatErrorMessage(error);
        this.alertService.setAlert(handleError);
      });
  }

  recover(): void {
    this.userService.resetPassword(this.email).then(
      () => {
        this.alertService.setAlert({ type: 'success', message: this.translateService.instant("__emailSentCheckEmails") });
      },
      error => {
        this.alertService.setAlert(this.alertService.formatErrorMessage(error));
      }
    );
  }

  confirm(): void {
    const uid = this.route.snapshot.paramMap.get('uid');
    const token = this.route.snapshot.paramMap.get('token');
    this.userService.confirmPassword(this.newPassword.value, this.confirmPassword.value, uid, token).then(
      () => {
        this.alertService.setAlert({ type: 'success', message: this.translateService.instant("__changePasswordSuccessfully") });
        this.router.navigate([ '/login' ]);
      },
      error => {
        this.handlePasswordError(error);
      }
    );
  }

  handlePasswordError(error: any) {
    if (error.error
      && error.error.non_field_errors
      && error.error.non_field_errors.length
      && error.error.non_field_errors[ 0 ] === 'Invalid password') {
      this.invalidPassReset = true;
    } else if (error.error && error.error.token) {
      this.alertService.setAlert({ type: 'error', message: this.translateService.instant("__resetTokenInvalid") });
    } else {
      this.alertService.setAlert({ type: 'error', message: error });
      this.invalidPassReset = false;
    }
  }

  navigatePath(): void {
    this.router.navigate([ this.userService.getMyInitialPath() ]);
  }

  get newPassword() { return this.resetForm.get('newPassword'); }
  get confirmPassword() { return this.resetForm.get('confirmPassword'); }
}
